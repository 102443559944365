import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editorial-board',
  templateUrl: './editorial-board.component.html',
  styleUrls: ['./editorial-board.component.scss']
})
export class EditorialBoardComponent implements OnInit {
  journal: any;
  Browse: any;
  ee: any;

  editorialBoardList = [





    {
      profilePic: '../../assets/images/Michael-Felsberg.png',
      name: 'Michael Felsberg',
      designation: 'Professor of Computer Vision, Linköping University, Sweden & School of Engineering, University of KwaZulu-Natal, South Africa.',
      researchInterests: 'Computer Vision; Machine Learning; Robot Vision.'
    },
    {
      profilePic: '../../assets/images/Songcan-Chen.jpg',
      name: 'Songcan Chen',
      designation: 'Professor, School of Computer Science and Technology/ Artificial Intelligence, Nanjing University of Aeronautics and Astronautics, China.',
      researchInterests: 'Pattern Recognition, Machine Learning and Neural Computing.'
    },
    {
      profilePic: '../../assets/images/Yudong_Zhang.jpg',
      name: 'Yu-Dong Zhang ',
      designation: 'Professor, School of Informatics, University of Leicester, UK.',
      researchInterests: 'Deep Learning, Medical Image Analysis, Artificial Intelligence.'
    },
    {
      profilePic: '../../assets/images/Kenzi Suzuki.jpeg',
      name: 'Kenji Suzuki',
      designation: 'Professor, Institute of Innovative Research (IIR), Tokyo Institute of Technology, Japan.',
      researchInterests: 'Deep learning, Machine learning, Computer-Aided Diagnosis, Artificial Intelligence, Biomedical Image Understanding.'
    },
    {
      profilePic: '../../assets/images/Tzung-Pei-Hong.png',
      name: 'Tzung-Pei Hong',
      designation: 'Chair Professor, Department of Computer Science and Information Engineering, AI Research Center, National University of Kaohsiung, Taiwan.',
      researchInterests: 'Knowledge Engineering, Data Mining, Soft Computing.'
    },
    {
      profilePic: '../../assets/images/Maria-Elena-Garcia-Diaz.jpg',
      name: 'Maria Elena Garcia Diaz',
      designation: 'Professor, Department of Computer Science, National University of Asunción, Paraguay',
      researchInterests: 'Machine Learning, Data Mining, e-health'
    },
    {
      profilePic: '../../assets/images/Paolo-Remagnino.png',
      name: 'Paolo Remagnino',
      designation: 'Professor of Artificial Intelligence and Machine Vision, Department of Computer Science, School of Computer Science and Mathematics, Kingston University, UK.',
      researchInterests: 'Computer Vision, Pattern Recognition, Machine and Deep Learning, Image and Video Understanding, Computational Botany, Distributed Intelligence.'
    },
    {
      profilePic: '../../assets/images/Sharon-Wang.jpg',
      name: 'Sharon Shui-Hua Wang',
      designation: 'Research fellow, Department of Mathematics, University of Leicester, UK.',
      researchInterests: 'Deep Learning, Biomedical Image Analysis, Pattern Recognition, Transfer Learning, Data Analysis'
    },
    {
      profilePic: '../../assets/images/Krzysztof_J_Cios.jpg',
      name: 'Krzysztof J Cios ',
      designation: 'Professor and Chair, Computer Science Department, Virginia Commonwealth University, USA.',
      researchInterests: 'Data Science, Data Mining, Machine Learning, Computational Neuroscience, Biomedical Informatics.'
    },
    {
      profilePic: '../../assets/images/Sarfraz.png',
      name: 'Muhammad Sarfraz',
      designation: 'Professor, Department of Information Science, College of Life Sciences, Kuwait University, Kuwait.',
      researchInterests: 'Computer Graphics, Computer Vision, Image Processing, Machine Learning, Pattern Recognition, Soft Computing, Data Science, Intelligent Systems, Information  Systems, Health Informatics, Cyber Security and Information Technology.'
    },
    {
      profilePic: '../../assets/images/FangXiang_WU.JPG',
      name: 'FangXiang WU',
      designation: 'Professor, Division of Biomedical Engineering, Department of Mechanical Engineering, Department of Computer Science,  University of Saskatchewan, SK Canada.',
      researchInterests: 'Deep/Machine Learning, Computational Biology, Health Informatics, Medical Image Analytics, Complex Bionetwork Analytics'
    },
    {
      profilePic: '../../assets/images/Hong-Chang.jpg',
      name: 'Hong Chang',
      designation: 'Professor, Institute of Computing Technology, Chinese Academy of Sciences, China.',
      researchInterests: 'Machine Learning, Pattern Recognition, Computer Vision.'
    },
    {
      profilePic: '../../assets/images/shahab.jpg',
      name: 'Shahab Shamshirband',
      designation: 'National Yunlin University of Science and Technology, Future Technology Research Center.',
      researchInterests: 'Explainable AI, Reinforcement Learning, Machine Learning, Knowledge-Based Systems, Computational Intelligence.'
    },
    {
      profilePic: '../../assets/images/Miguel-Garcia-Torres.jpeg',
      name: 'Miguel Garcia Torres',
      designation: 'Associate Professor, Department of Sports and Computer Sciences, Universidad Pablo de Olavide, Spain.',
      researchInterests: 'Data Mining, Machine Learning, Data Reduction, Bioinformatics, Astrostatistics.'
    },

    {
      profilePic: '../../assets/images/Abdulhamit_Subasi.jpg',
      name: 'Abdulhamit Subasi',
      designation: 'Professor, Institute of Biomedicine, Faculty of Medicine, University of Turku, Finland.',
      researchInterests: 'Biomedical Signal/Image Analysis, Machine Learning, Deep Learning, Artificial Intelligence.'
    },
    {
      profilePic: '../../assets/images/Heung-Il_Suk.jpg',
      name: 'Heung-II Suk',
      designation: 'Associate Professor, Dept. of Artificial Intelligence, Korea University, Korea.',
      researchInterests: 'Machine Learning, Deep Learning, Biomedical Data Analysis, Brain-Computer Interfaces, Healthcare, Computer Vision '
    },
    {
      profilePic: '../../assets/images/tdPham.jpg',
      name: 'Tuan D. Pham',
      designation: 'Senior Research Professor in AI,Founding Director, Center for AI Prince Mohammad Bin Fahd University, Saudi Arabia',
      researchInterests: 'Image Processing, Time Series, Pattern Recognition, Fuzzy-Set Algorithms, Genetic Algorithms, Neural Networks and Deep Learning, Geostatistics, Nonlinear Dynamics, Bioinformatics, Biomedical Informatics'
    },
    {
      profilePic: '../../assets/images/Yung-Kuan_Chan.jpg',
      name: 'Yung-Kuan Chan',
      designation: 'Lifetime Distinguished Professor, Department of Management Information Systems, National Chung Hsing University, Taiwan.',
      researchInterests: 'Image processing, Image retrieval, Image compression, Image hiding and Data mining.'
    },
    {
      profilePic: '../../assets/images/Prof-Paulo-Quaresma.jpg',
      name: 'Paulo Quaresma',
      designation: 'Professor, Department of Informatics, University of Evora, Portugal.',
      researchInterests: 'Natural Language Processing, Artificial Intelligence.'
    },
    {
      profilePic: '../../assets/images/foto_Zhao.jpg',
      name: 'Liang Zhao ',
      designation: 'Full Professor, Department of Computing and Mathematics (DCM), University of São Paulo, Brazil',
      researchInterests: 'Machine Learning, Graph based learning, Oscillatory Networks, Chaotic Neural Networks, Complex Networks.'
    },
    {
      profilePic: '../../assets/images/Chen.JPG',
      name: 'Cheng-Hong Yang',
      designation: 'Chair Professor, Department of Electronic Engineering, National Kaohsiung University of Science and Technology (NKUST), Taiwan.',
      researchInterests: 'Biomedical/Health informatics, Computational Intelligence.'
    },
    {
      profilePic: '../../assets/images/Wang-Han.jpg',
      name: 'Wang Han',
      designation: 'Associate Professor, Department of Computer Science, Nanyang Technological University, Singapore.',
      researchInterests: 'Computer Vision, Robotics.'
    },
    {
      profilePic: '../../assets/images/Djamel.png',
      name: 'Djamel Bouchaffra',
      designation: 'Director of Research Center for Development of Advanced Technologies, Algiers, Algeria.',
      researchInterests: 'Pattern Recognition and Machine Learning, Computer Vision, Artificial Intelligence, Cognitive Computing, Image and Signal Processing, Soft Computing.'
    },
    {
      profilePic: '../../assets/images/Li_Wang.jpg',
      name: 'Li Wang',
      designation: 'Assistant Professor, Director of Developing Brain Computing Lab, Department of Radiology and BRIC, University of North Carolina at Chapel Hill, USA.',
      researchInterests: 'Machine Learning, Artificial Intelligence, Infant Brain Analysis, Early Brain Development, Early Diagnosis of Autism'
    },
    {
      profilePic: '../../assets/images/Seunghoon-Hong.png',
      name: 'Seunghoon Hong',
      designation: 'Assistant Professor, School of Computing, KAIST, Korea.',
      researchInterests: 'Machine Learning and Computer Vision.'
    },
    {
      profilePic: '../../assets/images/Yu_Lequan.jpeg',
      name: 'Lequan Yu',
      designation: 'Assistant Professor, Department of Statistics and Actuarial Science, The University of Hong Kong, Hong Kong.',
      researchInterests: 'Medical Image Analysis, Machine Learning, Computer Vision, AI in Healthcare.'
    },
    {
      profilePic: '../../assets/images/ztao_JAIML.jpg',
      name: 'Zhiqiang Tao',
      designation: 'Assistant Professor, Department of Computer Science and Engineering, Santa Clara University, USA.',
      researchInterests: 'Machine Learning, Data Mining, Computer Vision.'
    },








  ]


  constructor() { }
  ngOnInit(): void {
    window.scroll(0, 0);



  }
  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }



}
