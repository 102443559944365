<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 mt-2">
                <p class="breadcrumb-text"><strong> Home / Journal Info / </strong> Keyword Index</p>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-sm-3 col-left mt-3">

            <app-home></app-home>

        </div>
        <div class="col-sm-2"></div>
        <div class="col-sm-5">
            <br><br>
            <img src="../../assets/images/coming.jpeg" width="100%" alt="">
            <br><br>




        </div>
    </div>
</div>