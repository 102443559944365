import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BodyComponent } from './body/body.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { AimComponent } from './aim/aim.component';
import { EthicsComponent } from './ethics/ethics.component';
import { EditorialComponent } from './editorial/editorial.component';
import { AuthorGuidelinesComponent } from './author-guidelines/author-guidelines.component';
import { ManuscriptSubmissionComponent } from './manuscript-submission/manuscript-submission.component';
import { EditorialBoardComponent } from './editorial-board/editorial-board.component';
import { CurrentIssueComponent } from './current-issue/current-issue.component';
import { ArticleInPressComponent } from './article-in-press/article-in-press.component';
import { KeywordIndexComponent } from './keyword-index/keyword-index.component';
import { PurposeOfPeerReviewComponent } from './purpose-of-peer-review/purpose-of-peer-review.component';
import { CallForPapersComponent } from './call-for-papers/call-for-papers.component';
import { ArticleProcessingChargesComponent } from './article-processing-charges/article-processing-charges.component';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent,
    BodyComponent,
    HomeComponent,
    ContactComponent,
    AimComponent,
    EthicsComponent,
    EditorialComponent,
    AuthorGuidelinesComponent,
    ManuscriptSubmissionComponent,
    EditorialBoardComponent,
    CurrentIssueComponent,
    ArticleInPressComponent,
    KeywordIndexComponent,
    PurposeOfPeerReviewComponent,
    CallForPapersComponent,
    ArticleProcessingChargesComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
