import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
 journal:Boolean;
  Browse:Boolean;
  href:any;
  constructor(private router : Router) {
    this.href = router.url
    // console.log(this.href);
    if(this.href == "/aims-scope" || this.href == "/editorial-process" || this.href == "/ethical-guidelines" || this.href == "/editorial-board" ){
      this.journal = true;
    }
    if(this.href == "/current-issue" || this.href == "/article-in-press" || this.href == "/keyword-index" ){
      this.Browse = true;
    }
    
    
  }
  

  ngOnInit(): void {
    window.scroll(0,0);
    this.href = this.router.url;

  }

}
