import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-processing-charges',
  templateUrl: './article-processing-charges.component.html',
  styleUrls: ['./article-processing-charges.component.scss']
})
export class ArticleProcessingChargesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
