import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-call-for-papers',
  templateUrl: './call-for-papers.component.html',
  styleUrls: ['./call-for-papers.component.scss']
})
export class CallForPapersComponent implements OnInit {
journal:any;
  Browse:any;
  constructor() { }

  ngOnInit(): void {
  }

}
