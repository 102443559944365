<div class="container-fluid"  #target>
  <div class="container">
      <div class="row">
          <div class="col-sm-6 mt-2">
              <p class="breadcrumb-text"><strong> Home / Journal Info / </strong> Editorial Board</p>
          </div>
      </div>
  </div>  
</div>

<div class="container">
    <div class="row">
        <div class="col-sm-3 col-left mt-3">
          
          <app-home></app-home>

      </div>

      <div class="col-sm-9">

        <div class="row">
          <div class="col-sm-12 mt-3">
            
            <h3><strong>Editorial Board</strong></h3>
            <hr class="seperator">
          </div>
        </div>

        <div class="container-fluid" id="backToTopID">
          <button class="btn float-right col-sm-1 scroll-btn" (click)="scrollToElement(target)"><strong><i class="fa fa-angle-up fa-lg"></i></strong></button>
        </div>

        <div class="editorial-section" *ngFor = "let editor of editorialBoardList; last as marginBottomSpace;">
          <div class="row speaker-section mt-3" [style.marginBottom]="marginBottomSpace? '50px' :'0px'">
            <div class="col-sm-3">
              
              <img src={{editor.profilePic}} width="150px" alt="">
            </div>
            <div class="col-sm-9 mt-3">
               <h5><strong>{{editor.name}}</strong></h5>
              <h6>{{editor.designation}}</h6>
              <h6><strong>Research Interests: </strong><br>
                 {{editor.researchInterests}}</h6>
            </div>
          </div>
        </div>

        


      </div>

    </div>
</div>
