import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BodyComponent } from './body/body.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { AimComponent } from './aim/aim.component';
import { EditorialComponent } from './editorial/editorial.component';
import { EthicsComponent } from './ethics/ethics.component';
import { AuthorGuidelinesComponent } from './author-guidelines/author-guidelines.component';
import { ManuscriptSubmissionComponent } from './manuscript-submission/manuscript-submission.component';
import { EditorialBoardComponent } from './editorial-board/editorial-board.component';
import { CurrentIssueComponent } from './current-issue/current-issue.component';
import { ArticleInPressComponent } from './article-in-press/article-in-press.component';
import { KeywordIndexComponent } from './keyword-index/keyword-index.component';
import { PurposeOfPeerReviewComponent } from './purpose-of-peer-review/purpose-of-peer-review.component';
import { CallForPapersComponent } from './call-for-papers/call-for-papers.component';
import { ArticleProcessingChargesComponent } from './article-processing-charges/article-processing-charges.component';



const routes: Routes = [
  {
    path: 'jaiml',
    component: BodyComponent
  },
  // {
  //   path:'', redirectTo : "/",
  //   pathMatch : 'full'
  // },
  {
    path: 'current-issue',
    component: CurrentIssueComponent
  },
  {
    path: 'article-in-press',
    component: ArticleInPressComponent
  },
  {
    path: 'keyword-index',
    component: KeywordIndexComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'aims-scope',
    component: AimComponent
  },
  {
    path: 'author-guidelines',
    component: AuthorGuidelinesComponent
  },
  {
    path: 'editorial-process',
    component: EditorialComponent
  },
  {
    path: 'ethical-responsibilites',
    component: EthicsComponent
  },
  {
    path: 'manuscript-submission',
    component: ManuscriptSubmissionComponent
  },
  {
    path: 'editorial-board',
    component: EditorialBoardComponent
  },
  {
    path: 'purpose-of-peer-review',
    component: PurposeOfPeerReviewComponent
  },
  {
    path: 'call-for-papers',
    component: CallForPapersComponent
  },
  {
    path: 'article-processing-charges',
    component: ArticleProcessingChargesComponent
  },
  {
    path: '**',
    component: BodyComponent
  }

];

@NgModule({

  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
