import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aim',
  templateUrl: './aim.component.html',
  styleUrls: ['./aim.component.scss']
})
export class AimComponent implements OnInit {
  journal:any;
  Browse:any;
  constructor() { }

  ngOnInit(): void {
    window.scroll(0,0);
  }

}
