<div class="container-fluid">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 mt-2">
        <p class="breadcrumb-text"><strong> Home / Journal Info / </strong> Article In Press</p>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-sm-3 col-left mt-3">
      <app-home></app-home>

      <!-- <h4 class="menu-item mb-2 " [routerLink]="['/']">Home</h4>
              <h4 class="menu-item mb-0" (click)="Browse =!Browse" >Browse
                <i *ngIf="!Browse" class="fa fa-angle-down text-light float-right menu-animation" aria-hidden="true"></i>
                <i *ngIf="Browse" class="fa fa-angle-up text-light float-right " aria-hidden="true"></i>
              </h4>
              <div *ngIf="!Browse">
                <h6 class="sub-menu-item mb-0 " [routerLink]="['/current-issue']"> &nbsp;&nbsp;&nbsp;Current Issue</h6>
              <h6 class="sub-menu-item mb-0 menu-active" [routerLink]="['/article-in-press']" >&nbsp;&nbsp;&nbsp;Article in Press</h6>
              <h6 class="sub-menu-item mb-2" [routerLink]="['/keyword-index']">&nbsp;&nbsp;&nbsp;Keyword Index</h6>
      
              </div>
              <h4 class="menu-item mt-2 mb-0" (click)= "journal =! journal">
                  Journal Info
                  <i *ngIf="!journal" class="fa fa-angle-down text-light float-right menu-animation" aria-hidden="true"></i>
                  <i *ngIf="journal" class="fa fa-angle-up text-light float-right " aria-hidden="true"></i>
              </h4>
              <div *ngIf="journal"> 
              <h6 class="sub-menu-item mb-0" [routerLink]="['/aims-scope']" >&nbsp;&nbsp;&nbsp;Aims and Scope</h6>
              <h6 class="sub-menu-item mb-0" [routerLink]="['/editorial']">&nbsp;&nbsp;&nbsp;Editorial Process</h6>
              <h6 class="sub-menu-item mb-0"[routerLink]="['/ethics']">&nbsp;&nbsp;&nbsp;Ethical Responsibilities</h6>
              <h6 class="sub-menu-item mb-0"[routerLink]="['/editorial-board']">&nbsp;&nbsp;&nbsp;Editorial Board</h6>
          </div>
      
              <h4 class="menu-item my-2" [routerLink]="['/author-guidelines']">Author Guidelines</h4>
              <h4 class="menu-item mb-2" [routerLink]="['/manuscript-submission']">Manuscript Submissions</h4>
              <h4 class="menu-item mb-2"  [routerLink]="['/contact']">Contact</h4>
        
        <button type="button" class="btn btn-primary col-sm-12 linkedin"><h4 class="mb-2">LinkedIn</h4></button> -->
      <!-- <a href="https://www.linkedin.com/in/editor-jaiml-680a56204/" target="-blank" class="mb-5 btn btn-block btn-social btn-linkedin linkedin">
          <span class="fa fa-linkedin"></span> LinkedIn
        </a> -->

    </div>
    <div class="col-sm-2"></div>
    <div class="col-sm-5">
      <br><br>
      <img src="../../assets/images/coming.jpeg" width="100%" alt="">
      <br><br>
    </div>
  </div>
</div>