
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit {

  journal: any;
  Browse: any;

  constructor() { }

  // downloadFile(){
  //   let Link=document.createElement("a");
  //   Link.download="downloadfile"
  //   Link.href="https://firebasestorage.googleapis.com/v0/b/appraj-bacb2.appspot.com/o/jaiml-header-bg-purple.png?alt=media&token=2cbfea48-8f81-4070-852f-3bdf6cbdef93"
  //   Link.click();

  // }

  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

}
