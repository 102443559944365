<div class="bg-image bg-opacity">
  <div class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-sm-4 mt-2">
          <p class="breadcrumb-text">
            <strong> Home /</strong> Author Guidelines
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-sm-3 col-left mt-3">
        <app-home></app-home>
      </div>
      <div class="col-sm-6" #target>
        <h3 class="mt-3">General Guidelines</h3>
        <p class="mt-3">Submissions are ought to include:</p>

        <ul>
          <li>
            Forename(s) and surnames of authors (refer Authorship section below)
          </li>
          <li>
            Author affiliations: department (unit), institution, city, country
          </li>
          <li>
            Corresponding author details: name, address, phone, fax, E-mail,
            ORCID ID
          </li>
          <li>Abstract word limit is 300</li>
          <li>
            Keywords should be 3-6(The MeSH keywords are suggested to use, as
            those words are easy for the reader while searching the contents in
            the article.)
          </li>
          <li>Running (short) title</li>
          <li>Reference list</li>
          <li>Double-spacing</li>
          <li>3-cm margins</li>
          <li>Page numbers</li>
          <li>Clear concise language</li>
          <li>American spelling</li>
          <li>Tables and figures should be cited.</li>
          <li>Microsoft Word is the desirable electronic format for text</li>
          <li>
            The units of measurement should use acknowledged abbreviations and
            International Systems of Units (Sl) symbols.
          </li>
          <li>
            Use International Systems of Units (Sl) symbols and recognized
            abbreviations for units of measurement
          </li>
          <li>Punctuating abbreviations e.g, et, al, ie is not preferred</li>
          <li>
            Acronyms in the abstract and paper should be clarified in the
            initial illustration and all the abbreviations should be made
            minimum are explained in their first usage.
          </li>
          <li>
            The word limit differs basing upon the type of article as follows:
          </li>
          <li>for Original article – 3500 words,</li>
          <li>for Review article – 4,500 words and</li>
          <li>for Case report – 1,500 words</li>
          <li>
            The Generic name should be given for the drugs mentioned in text,
            tables, and figures
          </li>
          <li>
            Suppliers of drugs, equipment, and other brand-name material are
            credited in parentheses (company, name, city, state, country).
          </li>
          <li>
            In consistent British or American English languages, a manuscript is
            ought to be written and for the contributions English is the only
            accepted language, hence if English is not the first language of the
            author then taking the help of a English native speaker is suggested
            for the benefit.
          </li>
        </ul>
      </div>

        <div class="container-fluid" id="backToTopID">
          <button class="btn float-right col-sm-1 scroll-btn" (click)="scrollToElement(target)"><strong><i class="fa fa-angle-up fa-lg"></i></strong></button>
        </div>
      
      <div class="col-sm-12">
        <h4 class="mt-3">Editing and Publishing Fee</h4>
        <!-- <p>The Submission of all the manuscripts to (JAIML) journal is assumed under the Gold Open Access publishing model.</p>
        <p>Presentations of all the manuscripts to (JAIML) journal  which are certified by both internal and external peer review were expected beneath  the Gold Open Access publishing model.</p> -->
        <p>
          All the articles published in the Journal of Artificial Intelligence
          and Machine Learning are published in full open access. In order to
          provide free access to readers, and to cover the costs of peer review,
          copyediting, typesetting, long-term archiving, and journal management,
          an article processing charge (APC) of USD 500 applies to papers
          accepted after peer review.
        </p>
        <p>
          Any reproduction is possible with a permission of the authors who
          retain copyrights under the following license agreement:
          <a
            target="_blank"
            href="http://creativecommons.org/licenses/by-nc/4.0/"
            >http://creativecommons.org/licenses/by-nc/4.0/.</a
          >
        </p>
        <p>
          Issues are published bi-annually. In case when Editorial Board
          received enough papers during one year, it is possible to print
          additional issue as special issue or supplement.
        </p>

        <h4 class="mt-3">Authorship</h4>
        <ul>
          <li>
            Significant donations to hypothesis and sketch, data collection or
            evaluation and data interpretation;
          </li>
          <li>
            Meticulously drafting or reviewing the article for the fundamental
            expertise;
          </li>
          <li>Supreme endorsement of the version to be published; and</li>
          <li>
            Liability covenant on all the issues of work is ought to be made to
            assure that all the queries relating to the accuracy or integrity of
            any portion of work are adequately examined and determined;
          </li>
          <li>
            Assurance of all queries relating to the accuracy or integrity of
            any portion of work are adequately examined and determined is
            reaffirmed by the liability covenant on all the issues of work.
          </li>
        </ul>

        <p>All the four criteria should be met.</p>
        <p>
          The officials, who fulfill the criteria for
          authorship/contributorship, are identified in a large, multicenter
          group while performing the work as they approve the direct liability
          of the manuscript and the editors will query them to finish author
          contributions and conflict-of-interest disclosure forms
          <a target="_blank" href="http://www.icmje.org/conflicts-of-interest/">
            (http://www.icmje.org/conflicts-of-interest/)</a
          >. The preferable citation and the identity of all the official
          authors along with their group name is ought to be reported by
          corresponding author while submitting a manuscript authored by a
          group. The name of the officials who is in charge for the manuscript
          along with their group name is cataloged by the NLM along with the
          record of the acknowledged collaborators names.
        </p>
        <p>
          Authorship embodies financial possession, data collection, or
          management of the research team along with the list of all the
          assigned authors who qualified authorship.
        </p>
        <p>
          Adequate involvement in the suitable portions of work by each author
          is anticipated.
        </p>
        <p>
          The authorship of multicenter trials is assigned to a group
          progressively and the above criteria for authorship/contributorship is
          ought to be fulfilled by all the designated authors of the group.
        </p>
        <p>
          Prior to the submission of the manuscript for the publication there
          must be a conclusion about the contributors/authors and their
          statistics regarding their presence and others should be made clear by
          the corresponding author/guarantor. Choosing the
          authorship/contributorship or arbitrating the conflicts to authorship
          is not a task of the editors.
        </p>
        <p>
          As per the management of conflict of interest while writing the peer
          review and the editorial resolution adoption the public trust is
          counted in the peer review process and the reliability of published
          articles.
        </p>
        <p>
          The article will be forwarded to the first author subsequent to the
          primal review from Editorial Board for double-blind peer review
          (reviewer should be a scientist with PhD title from the related field
          to the article or a professional worker that has more than 20 years of
          experience in that field without PhD title) with a minimum of 10
          published papers in cataloged journals.
        </p>
        <p>
          A short-term conclusion is forwarded to corresponding author
          subsequent to the twin review from Editorial Board for validation to
          the Editor’s progress in editing process.
        </p>
        <p>
          Conflict of Interest prevails in case where an author (or the author’s
          institution), reviewer or editor has commercial or private
          relationships which may inadequately effect (bias) his or her activity
          (such relationships are referred to as dual commitments, competing
          interests, or competing loyalties) and these relationships. Not all
          relationships portrays true conflict of interest as there will be
          contrast to negligible potential from those with great potential to
          impact judgment and conflict of interest prevail the potential without
          any significant difference upon individual’s assumption for the affect
          of relationship on scientific judgment. The instantly recognizable
          conflicts of interest and probably damaging the credibility of the
          journal, the authors, and of science itself are the financial
          relationships (such as employment, consultancies, stock ownership,
          honoraria, paid expert testimony) and conflicts may happen regardless
          of further reasons like personal relationships, academic competition,
          and intellectual passion. (International Committee of Medical Journal
          Editors ("Uniform Requirements for Manuscripts Submitted to Biomedical
          Journals") -- February 2006).
        </p>
        <p>
          Right to privacy is accessible to the patients who should not violate
          the informed consent and the patients who are identifiable be shown
          for the publication of the manuscript and written informed consent is
          collected from the patient (or parent or guardian) while the
          identification details including patients' names, initials, or
          hospital numbers, should not be published in disclosure, photographs,
          and ancestries until requirement of the details for scientific
          purposes. Authors should identify Individuals who provide writing
          assistance and disclose the funding source for this assistance.
        </p>
        <p>
          Remove the unimportant identification details if required and it is
          hard to attain complete anonymity, in case of any queries informed
          consent can be procured. For example, masking the eye region in
          photographs of patients is inadequate protection of anonymity. The
          editors should record that an assurance is ought to be given by the
          authors upon alteration of the identification details without any
          distortion of scientific meaning for the protection anonymity, such as
          in genetic pedigrees.
        </p>
        <p>
          The journal's instructions for authors must be accompanied with the
          condition for informed consent before mentioning in the published
          article.(International Committee of Medical Journal Editors ("Uniform
          Requirements for Manuscripts Submitted to Biomedical Journals") --
          February 2006)
        </p>
        <p>
          The authors should mention whether the ethical standards of the
          responsible committee on human experimentation (institutional and
          national) and with the Helsinki Declaration of 1975, as revised in
          2000 (5) are followed while submitting the experiments on human
          subjects and if any query arises then the author should justify their
          approach and evidence specifically that all the suspicious aspects of
          study on the institutional review body is approved. All the authors
          should be queried while reporting experiments on animals, about
          cautions and utilization of the institutional and national guide for
          laboratory animals.. (International Committee of Medical Journal
          Editors ("Uniform Requirements for Manuscripts Submitted to Biomedical
          Journals") -- February 2006).
        </p>

        <h4 class="mt-3">Contributors Listed In Acknowledgment</h4>

        <p>
          It is prominent for the contributors that all the standards for
          authorship are fulfilled and in failure, the list of all contributors
          who are not fulfilling the standards is ought to be mentioned in the
          acknowledgements section. The selection of the acknowledged person is
          the one who supplied purely technical help, writing assistance, or a
          department chairperson who provided only general support. The
          endorsing entity of the published article and the identification
          details of individuals responsible for the assistance with steady
          design, data collection, data analysis, or manuscript preparation to
          the Authors is ought to be stated along with the acknowledgement of
          Financial and material endorsement .
        </p>
        <p>
          The contributions to the paper publication is accepted materially and
          under the headings such as “clinical investing” or “participating
          investigators”, the list of all the contributors who do not meet the
          authorship is provided along with their responsibilities or
          contributions ought to be explained for which as an illustration -
          “served as scientific advisors.” “Critically reviewed the study
          proposal.” “Collected data.” or “provided and for study patients.”and
          they acknowledged in view of readers assuming the endorsement of the
          data and conclusions.
        </p>

        <h4 class="mt-3">Reference Style</h4>
        <p>
          Journal of Artificial Intelligence and Machine Learning follows the
          MEDLINE/PubMed or Vancouver styles which are based on the style
          developed by the International Committee of Medical Journal Editors in
          1978 in Vancouver.
        </p>
        <p>
          A reference is ought to be precisely verified, concluded, updated,
          relevant handy brochures. Randomly if isn’t concluded then the further
          information can be compiled through some of the best websites
          preferably
        </p>

        <ul class="center">
          <li>
            National Library of Medicine -
            <a href="www.nlm.nih.gov"> www.nlm.nih.gov</a>
          </li>
          <li>
            Books in Print -
            <a href="www.booksinprint.com"> www.booksinprint.com</a>
          </li>
          <li>
            PubMed -
            <a href="www.ncbi.nlm.nih.gov/PubMed/">
              www.ncbi.nlm.nih.gov/PubMed/</a
            >
          </li>
          <li>Or individual publisher Websites.</li>
        </ul>

        <ul>
          <li>
            Before the commencement of references signifying after the
            termination of text, a page break is ought to be enclosed and the
            article text should be written after the reference.
          </li>
          <li>
            The numerical arrangements are recommended by using superscript
            numbers in the text than alphabetical arrangement of references.
          </li>
          <li>
            The names of all the authors should be recorded and if the count
            exceeds six authors, then the initial three author’s names is
            recorded followed by et al.
          </li>
          <li>
            Designing the references is preferred in significance to the below
            examples:
          </li>
        </ul>

        <ol>
          <li><h5>Articles in Journals</h5></li>
          <p>
            Rose ME, Huerbin MB, Melick J, Marion DW, Palmer AM, Schiding JK, et
            al. Regulation of interstitial excitatory amino acid concentrations
            after cortical contusion injury. Brain Res. 2002;935(1-2):40-6.
          </p>
          <li><h5>Books and Other Monographs</h5></li>
          <p><strong>Chapter in a book:</strong></p>
          <p>
            Meltzer PS, Kallioniemi A, Trent JM. Chromosome alterations in human
            solid tumors. In: Vogelstein B, Kinzler KW, editors. The genetic
            basis of human cancer. New York: McGraw-Hill; 2002. p. 93-113.
          </p>
          <p><strong>Conference Proceedings:</strong></p>
          <p>
            Harnden P, Joffe JK, Jones WG, editors. Germ cell tumours V.
            Proceedings of the 5th Germ Cell Tumour Conference; 2001 Sep 13-15;
            Leeds, UK. New York: Springer; 2002.
          </p>
          <p><strong>Conference paper:</strong></p>
          <p>
            Christensen S, Oppacher F. An analysis of Koza's computational
            effort statistic for genetic programming. In: Foster JA, Lutton E,
            Miller J, Ryan C, Tettamanzi AG, editors. Genetic programming.
            EuroGP 2002: Proceedings of the 5th European Conference on Genetic
            Programming; 2002 Apr 3-5; Kinsdale, Ireland. Berlin: Springer;
            2002. p. 182-91.
          </p>
          <li><h5>Unpublished Material</h5></li>
          <p>
            Alvarez R. Near optimal neural network estimator for spectral x-ray
            photon counting data with pileup. arXiv:1702.01006v1 [Preprint].
            2017 [cited 2017 Feb 9]: [11 p.]. Available from:
            https://arxiv.org/abs/1702.01006
          </p>
          <li><h5>Electronic Material</h5></li>
          <p><strong>Journal article on the Internet:</strong></p>
          <p>
            Abood S. Quality improvement initiative in nursing homes: the ANA
            acts in an advisory role. Am J Nurs [Internet]. 2002 Jun [cited 2002
            Aug 12];102(6):[about 1 p.]. Available from:
            <a
              href="https://journals.lww.com/ajnonline/Fulltext/2002/06000/Quality_Improvement_Initiative_in_Nursing_Homes.31.aspx"
              target="_blank"
            >
              https://journals.lww.com/ajnonline/Fulltext/2002/06000/Quality_Improvement_Initiative_in_Nursing_Homes.31.aspx </a
            >Subscription required.
          </p>
        </ol>

        <h4 class="mt-3">Figures And Tables</h4>
        <ul>
          <li>
            The primo graphics is merely approved and if any replicate from
            other media are used then copyrights holders’ authorization is ought
            to be acquired.
          </li>
          <li>
            The augments can be included in the Tables without any replication
            of the text.
          </li>
          <li>
            The numerical order should be given similar to the mentioned
            quotation in the text.
          </li>
          <li>A fresh page is ought to be used for each table</li>
          <li>
            A brief title relating the details in the table which explains the
            reason for categorizing the data in the table is can be given at the
            top of each table to avoid a repeating labels or columns and rows of
            the table.
          </li>
          <li>
            In footnotes section, the statistical and other details is ought to
            be provided.
          </li>
          <li>
            The identical material should not be replicated in figure and in
            table and ideally mention in the figure.
          </li>
          <li>
            Any sort of data which is not addressed in the manuscript text
            should be excluded from the tables.
          </li>
          <li>
            In the submission and review stages, embedded image files are
            accepted for tables.
          </li>
        </ul>

        <p>
          Number figures in the order in which they are discussed in the text.
          Group the figure legends in numerical order on one or more pages,
          separate from the figures. The figure title should be given as the
          first two words of the legend. All tables and figures should be
          referenced in the main article text in result’s section, for example
          like “(Table 1)” or “(Figure 1)”.
        </p>

        <ul>
          <li>Individual files are ought to be submitted</li>
          <li>Numerical digits are ought to be used consecutively</li>
          <li>A brief title or legend is ought to be given</li>
          <li>After tables, abbreviations are ought to be placed</li>
          <li>Identifiers are ought to be used as superscript a,b,c…</li>
          <li>Figures are ought to be submitted in PDF or JPG files format</li>
          <li>
            Supply Line Art 900 dpi, Combination (Line Art + Halftone) 900 dpi,
            Halftone 300 dpi
          </li>
          <li>Webpage downloaded graphics are not accepted</li>
          <li>
            Multi-panel figures are ought to be submitted by including the
            different parts with labeling as a,b,c,d as a single file
          </li>
        </ul>

        <h5 class="mt-3">Formulas</h5>
        <p>
          The formulas are hinted to be created as text to save them in your
          document for their staging them so that they randomly appeared in the
          system-generated proof and any notable ‘dots’, ‘dashes’, ‘minussigns’,
          etc. that can make the formula to misinterpret should be handled.
        </p>
        <h5 class="mt-3">Appendices</h5>
        <p>
          A manuscript will be mainstreamed with attachments and seldom with
          appendices where the publication of appendix is affirmed by the
          author. Ultimately appendix will be published unaltered online in the
          version of manuscript.
        </p>

        <h3 class="mt-3">Digital Artwork Preparation</h3>
        <h5 class="mt-3">General Guidelines</h5>

        <ul>
          <li>
            Adobe Photoshop is the fine recommendation for designing and saving
            of images, while Adobe Illustrator is recommended for line art and
            labels.
          </li>
          <li>
            The arts which are designed in Microsoft Excel, Word, or PowerPoint
            are not viable by the typesetter; hence such arts should not be
            filed.
          </li>
          <li>
            A considerable number of files should be accessible, as each figure
            is ought to be saved distinctly.
          </li>
          <li>Compacting the files is disabled.</li>
          <li>
            The resolution of every artwork is measured in dpi (dots per inch)
            which also varies in their format. The resolution of all
            black-and-white and color artwork is ought to be 300 dpi in TIFF
            format while line artwork is ought to be 1,200 dpi either in EPS or
            TIFF format.
          </li>
          <li>
            The art can be submitted either smaller or larger or in its correct
            orientation, hence cropping the figure into their original final
            size (approximately 3½ inches for a single column and up to 7 inches
            for a double column) is recommended which will prevent the changes
            in the resolution or the clarity of the figure.
          </li>
        </ul>

        <p>
          Note: The resolution implied for sharp, clear, detailed images is
          300dpi but JPEG format is actually created for quick upload on
          computer screens with lower resolution (compressed) format less than
          300 dpi. Hence for grayscale and color artwork is unsatisfactory.
        </p>

        <h5 class="mt-3">Supplementary Data</h5>
        <p>
          Supplementary retain to 6 typeset pages or word limit of 2,400 and on
          exceeding this limit an external link should be given on the website
          or the institute’s website. The data like video files or other parts
          of original manuscript to view the operations, procedures etc are
          acknowledged as supplementary data.
        </p>

        <h3 class="mt-3">Manuscript Submission</h3>
        <p>
          All manuscripts should be submitted online or as an attachment to our
          email
        </p>
        <p>
          By doing so, authors agree to the terms and conditions of ethical
          submission. Simultaneous submission of a manuscript, considered for
          publication elsewhere, is absolutely unacceptable. Requests for
          secondary publication of our journal articles in other media should be
          forwarded to the publisher.
        </p>
        <p>
          If any suspicion of data falsification, fabrication, manipulation,
          plagiarism, or other forms of misconduct in submitted or published
          articles is raised, our editors will act in accordance with the
          Committee on Publication Ethics (COPE) guidelines
          (http://publicationethics.org/resources/guidelines), which may result
          in retractions and banning responsible authors from publication in our
          journals.
        </p>
        <h5 class="mt-3">Pre-Submissions</h5>
        <p>
          An abstract or draft manuscript to verify the appropriateness of the
          papers of authors can be sent to the Editors and they will perform the
          assessment basing upon the subject matter vs the aims and scope for
          submitting to the journal by quick review (not peer review) of the
          paper but not as a complete manuscript review.
        </p>
      </div>
    </div>
  </div>
</div>
