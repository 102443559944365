<div class="container">
<div class="row">
    <div class="col-sm-3 col-left mt-3">
      
      <app-home></app-home>


  </div>

  


    <div class="col-sm-6" #target>
       
        <p class="mt-3">The publication of research papers of a journal along with the prevailing research topics in their original,
high quality is the primal aspiration of Journal of Artificial Intelligence and Machine Learning</p>
        <p>With the enlightenment that JAIML is an “Open access”, as a better choice of the scientific community,
all types of papers upon publication will immediately be made available online, world-word, barrier-free
and permanently accessible to the complete text of research papers.</p>
        <p>High visibility for maximum global exposure with open access publishing model rigorous peer review
(double blind peer-review) of research papers prompt faster publication.</p>
        <p>All the viewpoints of publication ethics of the Committee on Publication Ethics (COPE), which is a forum
for editors and publishers of peer reviewed journals, are esteemed by JAIML. The guidelines in specific
to the management of mishandling a case of research and publication are given to editors and
publishers by COPE on all view points of the publication ethics. The examination of the particular cases is
not with held by COPE but ensures the editors that the cases are investigated by the appropriate
authorities (usually a research institution or employer).</p>
       <img class="offset-4 open-access" src="../../assets/images/open-access-ful.png" style="height:80px" >
        <p>The &quot;COPYRIGHT TRANSFER FORM&quot; can be downloaded from <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/appraj-bacb2.appspot.com/o/copy%20right%20form.docx?alt=media&token=96a6b207-0608-4572-91a4-a691cc12e0c4">here</a>.  All the mandatory details
should be filled by the corresponding authors of each manuscript; signature is made and submitted by
including all the mandatory details as stated below. Upon submitting the form, all the copyrights will be
forwarded to JAIML.</p>  
<p>Presentation of a manuscript assumes that </p>
        <ul>
          <li>
            <p>There are no prior publications of the stated work (besides in the form of an abstract or as part of a
published lecture, review, or thesis).</p>
          </li>
          <li>
            <p>Contemplation for publication is rejected.</p>
          </li>
          <li>
            <p>Its publication has been approved by all coauthors, if any, as well as by the responsible authorities at
the institute where the work has been carried out.</p>
          </li>
          <li>
            <p>The agreement of spontaneous transfer of the copyright to the publisher is ought to be made by the
author upon the acceptance of their manuscript for publication</p>
          </li>
          <li>
            <p>Republication of the manuscript is rejected.</p>
          </li>
  
        </ul>
        <p>Journal of Artificial Intelligence and Machine Learning encourages researchers to submit relevant research works within the scope of the journal. To submit manuscripts, authors can send their manuscripts directly to our Editorial Office at<a href="mailto:editor@jaiml.org"> editor@jaiml.org</a> or <a href="mailto:submissions@jaiml.org"> submissions@jaiml.org.</a></p>
        
      </div>
      <div class="col-sm-3 mt-3">
        <img src="../../assets/images/flyer-sidemenu.jpg" width="100%" alt="">
      <br><br> 
      
      
      <a class="twitter-timeline" data-width="300" data-height="500" data-theme="dark" href="https //twitter.com/J_ArtIntML?ref_src=twsrc%5Etfw">Tweets by J_ArtIntML</a> 
      
    
</div>
</div>
<div class="container-fluid" id="backToTopID">
  <button class="btn float-right col-sm-1 scroll-btn" (click)="scrollToElement(target)"><strong><i class="fa fa-angle-up fa-lg"></i></strong></button>
</div>