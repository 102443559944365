import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare let Email;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'journalaiml';
  emailSubscribe: FormGroup;
  NewsletterSuccessMsg = false;

  scrollToFeesSection() {
    window.scroll(0, 1300);
    // ="/author-guidelines"
  }

  constructor(public formBuild: FormBuilder) {

    this.emailSubscribe = this.formBuild.group({
      emailId: ['', [Validators.required, Validators.email]]
    })
  }





  subscribe() {
    // Email.send({
    //   Host: "smtp.elasticemail.com",
    //   Username: "spatnala2@gmail.com",
    //   Password: "66EAB6DE47C7DE812E4E3BD23CD0BFFC14F8",
    //   To: this.emailSubscribe.value.emailId,
    //   From: "spatnala2@gmail.com",
    //   Subject: "Thanks for Subscribing",
    //   Body: `<h4> Thank you for subscribing our newsletter. We will make sure to send our updates to you. </h4>`
    // }).then(() => {

    Email.send({
      Host: "smtp.elasticemail.com",
      Username: "contact@jaiml.org",
      Password: "7472ECA0FBE4E4C17089663C3E0EE5F2733E",
      To: "contact@jaiml.org",
      From: "contact@jaiml.org",
      Subject: "You got one subscription",
      Body: `
        <h4>Hello Prateek, </h4>
        <p>You got one subscription with this email id: ${this.emailSubscribe.value.emailId}.</p>
        `
    })
      .then(() => {
        this.NewsletterSuccessMsg = true;
        setTimeout(() => {
          this.NewsletterSuccessMsg = false;
        }, 3000)
        this.emailSubscribe.reset();
      }

      );

  }
}
