import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-current-issue',
  templateUrl: './current-issue.component.html',
  styleUrls: ['./current-issue.component.scss']
})
export class CurrentIssueComponent implements OnInit {
  journal:any;
  Browse:any;
  constructor() { }

  ngOnInit(): void {
  }

}
