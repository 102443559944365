<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 mt-2">
                <p class="breadcrumb-text"><strong> Home / </strong> Article Processing Charges</p>
            </div>
        </div>
    </div>  
  </div>
  
  <div class="container">
      <div class="row">
          <div class="col-sm-3 col-left mt-3">
            
            <app-home></app-home>
  
        </div>
        <div class="col-sm-9 mt-3 p-3 mb-3">
            <p>All the articles published in the Journal of Artificial Intelligence and Machine Learning are published in full open access. In order to provide free access to readers, and to cover the costs of peer review, copyediting, typesetting, long-term archiving, and journal management, an article processing charge (APC) of USD 500 applies to papers accepted after peer review.</p>
                
        </div>
      </div>
  </div>
  