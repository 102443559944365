import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-in-press',
  templateUrl: './article-in-press.component.html',
  styleUrls: ['./article-in-press.component.scss']
})
export class ArticleInPressComponent implements OnInit {
  journal:any;
  Browse:any;
  constructor() { }

  ngOnInit(): void {
  }

}
