<div class="container-fluid" #target>
    <div class="container">
        <div class="row">
            <div class="col-sm-5 mt-2">
                <p class="breadcrumb-text"><strong> Home / Journal Info / </strong> Editorial Process</p>
            </div>
        </div>
    </div>  
  </div>
  <div class="container">
      <div class="row">
        <div class="col-sm-3 col-left mt-3">
        <app-home></app-home>

        </div>

          <div  class="col-sm-6">
            <h3 class="mt-3">Editorial Process</h3>
            <p>
                All the received manuscripts are evaluated based on the journal ethical policies. Upon meeting the journal’s standard, the manuscript is further processed. 
            </p>
            <h5 class="mt-3">Pre-Quality Check </h5>
            <p>
                The received manuscripts initially will go through the plagiarism check. We use iThenticate Crossref. Decision for the acceptance and rejection will be thoroughly scrutinized, and informed to the author. Dealing the article related issues will be under COPE guidelines. The accepted article will be proceeded further. Rejected articles will be informed to author for the revision or resubmission. 
            </p>
            <h5 class="mt-3">Peer Review </h5>
                <p>
                    The accepted manuscript will be assigned for the double-blind peer review. Subject experts who hold the PHDs will be contacted for the reviews. The manuscript is reviewed more than twice for assessment of the quality. Reviewers suggested by the author may also be considered. 
                </p>
            
           </div>
          <!-- <div class="col-sm-3 mt-3">
            <div class="right-side">
                <h4>Our Address</h4>        
                <address class="address-text">
               <strong>  Shahrood of Technology</strong>  <br>      
          Scientific Journals Office, <br> Deputy of Research & Technology,<br> Shahrood University of Technology,<br> University Blvd., 7th Tir Square,<br> Shahrood, Iran.
          <br></address>
          <i class="fa fa-phone" aria-hidden="true"></i>
          +98(23) 32300338
          <br>
          <i class="fa fa-envelope" aria-hidden="true"></i>
              JAIDM@shahroodut.ac.ir
              </div>
          </div> -->
          <div class="container-fluid" id="backToTopID">
            <button class="btn float-right col-sm-1 scroll-btn" (click)="scrollToElement(target)"><strong><i class="fa fa-angle-up fa-lg"></i></strong></button>
          </div>
        
         
    
        <div class="container">
          <div class="row">
              <div class="col-md-9">
                
                <p>
                    *(Reviewer should not indulge in any work and manuscripts published by the author or the co-authors for the past five years).
                    Minimum of two comments will be received.
                </p>
                <h5 class="mt-3">Editor’s Decision and Revision </h5>
                <p>The reviewed manuscript with the associate comments will be further taken to the Editor-in-chief’s notice.</p>
                <h6 class="mt-3"> Revision </h6>
                <p>Manuscripts requires revision will be returned to the author. Author will be notified for the major and minor revisions. </p>
                <p>The revised manuscript will again be under the process of double-blind peer review. The process will be continued until no revisions. </p>
                <h6 class="mt-3">Rejection </h6>
                <p>Author failing to meet the requirements of the reviewer comments or the Editor-in-chief decision will be asked to resubmit the manuscript with revision. And further failing to submit with revision will be rejected.</p>
                <h5>Previously Published Articles </h5>
                <p>Artificial Intelligence and Machine Learning journal will not encourage submission of the previously published article. Noticing of these manuscripts at any stage of the process will be rejected without any prior notice. Withdrawn article from the other journals will be accepted under proper explanation from the author. Substantial work of the accepted article resembles any of the previous publications in other journal needs an appropriate explanation and if the manuscript depicts the work from the other journal’s article, please include the similarities and contrasts with the other paper.</p>
                <h6 class="mt-3">Submission of the Manuscript </h6>
                <p>Accepted manuscripts are subjected not to publish in any other journal and its publication has not been published before. The work must be approved by all the co-authors, if any, institutions. Any legally claims or related will not be held responsible by publisher for compensation. </p>
                <h6 class="mt-3">Permission </h6>
                <p>Manuscripts requires data, tables, figures etc., which are copy right, author has to take the permission and has to present the evidence of permission.  </p>
                <h5 class="mt-3">Manuscript Withdrawal Policy </h5>
                <p>Author should hold the responsibility in deciding the which article among the submitted articles ought to go for publishing. Decisions in this regard are guided by the journal’s editor and is limited by ethical conducts such as infringement of the copyright and plagiarism. We intended to emphasize all the articles submitted and archived as a significant scholarship and indefinite. All the submitted article are archived, maintained and perpetual. Circumstances in regard to the submitted article withdrawal or article retraction are considered significantly and processed by consulting all the relevant author, co-authors and editorial board members.</p>
                <p>This policy has been designed to address these concerns and to take into account current best practice in the scholarly and library communities.  As standards evolve and change, we will revisit this issue and welcome the input of scholarly and library communities. We believe these issues require international standards and we will be active in lobbying various information bodies to establish international standards and best practices that the publishing and information industries can adopt.  </p>
                <h5 class="mt-3">Article Withdrawal </h5>
                <p>Under prevailing the conditions of stating the previous version of the article or consisting of errors or submitted twice without the knowledge, article will be withdrawn. In addition to, if any article is observed to violating the journal ethics, like numerous submission, fake claims of authorship, plagiarism, fraudulent usage or in such manner. The articles maybe “Withdrawn” from JAIML if any of the acknowledged articles which are not yet published officially or the articles which may not have entire volume or found as a copy from other articles or involving any errors or violating the journal ethics in perspective of the editors (like numerous submissions, fake claims of authorship, plagiarism, fraudulent usage or in such manner). The word Withdrawn is meant by the removal of the article content (PDF) and replacing with a PDF which can be indicated that in connection to the currency policy document, the article is withdrawn by following the Journal Policy on Article in Press Withdrawal.</p>
                <h5 class="mt-3">Article Retraction </h5>
                <p>Under certain circumstances, retraction is applied rectifying the errors like the violation of the professional ethical codes including the numerous submissions, fake claims of authorship, plagiarism, fraudulent usage or in such manner. In the systematic traits of the scholarly world, the profound community members can suggest it’s authors or the editors about the retraction procedures of an article. Various library and scholarly authorities are liable for enhancing the retraction standard ethics which, JAIML endorsed as a proven methodology. </p>

                <ul>
                    <li>Report the validation note of the authors and/or the editors which is titled as “Retraction  [article title]” in the paginated part of the journals further issues and included in the contents table.
                    <li>A connection link is prepared to the novel article in the e-version, an electronic version or computerized version.</li>
                    <li>A connection link is resolved by a screen which includes the retraction note followed by the online article; hence the reader can be directed to the article.</li>
                    <li>The water mark on the .pdf is indicating as “retracted” on every single page of novel article which is left unchanged.</li>
                    <li>Various versions in the document are accepted except the HTML version which is ought to be removed.</li>
                </ul>

                <h5 class="mt-3">Article Removal  Legal Limitations</h5>
                <p>The metadata (Title and Authors) will exist when a screen substitutes the text by explaining that because of the legal issues article is eliminated upon the limited number conditions. There might be a severe health trigger when there is defamation or violation of legal rights in the article or the whereabouts of the article in accordance to the court guidelines.</p>                
                <h5 class="mt-3">Article Replacement </h5>
                <p>The authors of the novel articles desires to retract the article when the article is directing to the severe health trigger and replace the article with the rectified version. After the retraction steps the database ought to publish a connection link of the rectified version of the article by including the details of the rectified document.</p>
  </div>
  <!-- <div class="col-sm-3">
    <div class="right-side mb-5">
      <h5>Website Traffic Statistics</h5>
      <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cum rerum quam molestias ipsa inventore ad.</p>
    </div>
  </div> -->
 
          </div>
        
          
      </div>
     
  </div>