import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ethics',
  templateUrl: './ethics.component.html',
  styleUrls: ['./ethics.component.scss']
})
export class EthicsComponent implements OnInit {
  journal: any;
  Browse: any;
  constructor() { }

  scrollToTop() {
    window.scroll(0, 0);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }


  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

}
