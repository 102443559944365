<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 mt-2">
                <p class="breadcrumb-text"><strong> Home / Journal Info / </strong> Ethical Responsibilities</p>
            </div>
        </div>
    </div>  
  </div>
  <div class="container" #target>
      <div class="row">
        <div class="col-sm-3 col-left mt-3">
          <app-home></app-home>
        

    </div>

          <div class="col-sm-6">
            <h3 class="mt-3">Ethical Responsibilities</h3>
            <h5 class="mt-3">Responsibilities of Author</h5>
            <h6 class="mt-3">Reporting Standards </h6>
            <p>Reporting of the manuscripts should meet the journal standards and all the significant work performed must be precise. Papers suffice details and references for the consent of replicating the work. Practices and articulations establishing untrustworthy conduct intentionally or unwittingly are unsatisfactory.</p>
            <h6 class="mt-3">Data Access and Retention </h6>
            <p>Work invested in the manuscript opting permission may ask to provide for the review and comply with the open data prerequisite of the journal.</p>
            <h6 class="mt-3">Originality and Acknowledgment of Source </h6>
            <p>Authors confiding the amount of data from another research or any related information, should be properly cited and all the required permissions should be granted. Acknowledgment ought to be provided for all the supporting work. Data retracted directly or indirectly must be used under certain consent from the source. </p>
             
          </div>
          <!-- <div class="col-sm-3 mt-3">
            <div class="right-side">
                <h4>Our Address</h4>        
                <address class="address-text">
               <strong>  Shahrood of Technology</strong>  <br>      
          Scientific Journals Office, <br> Deputy of Research & Technology,<br> Shahrood University of Technology,<br> University Blvd., 7th Tir Square,<br> Shahrood, Iran.
          <br></address>
          <i class="fa fa-phone" aria-hidden="true"></i>
          +98(23) 32300338
          <br>
          <i class="fa fa-envelope" aria-hidden="true"></i>
              JAIDM@shahroodut.ac.ir
              </div>
          </div> -->
          
      </div>
      

      <div class="row">
        <div class="col-sm-9">
            <p>Any sort of Plagiarism is not accepted. Claiming others research, doping off the papers, using copy righted data without proper consent etc., is unethical and not encouraged. </p>
            <h6 class="mt-3">Multiple, Redundant or Concurrent Publication </h6>
             <p>Papers reflecting same work of the previous papers of the primary Journal are not considered. And submitting published papers from other journals are also unaccepted.</p>
             <p>Any paper published in other journal should not be submitted for reviews. Certain related types of conceptual, academic propositions, digital preprints are acknowledged.</p>
            <p>It is often legal under the terms to publish some sort of articles (like clinical guidelines, translations) into different journals by considering the identical data and the interpretation of the original document as a replica in agreement with the concerning authors and editors. The citation of the primary reference is in the secondary publications and their additional details can be found in ICMJE.</p>
            <h6 class="mt-3">Confidentiality </h6> 
             <p>Any research data with the respective consent/funding sources (if required)/grant numbers (if required)/references (if required) ought to be maintain confidential. </p>
             <h5 class="mt-3">Authorship of the paper </h5>
             <p>Contributor who have made all the efforts towards the manuscript’s abstract, research, design, process etc.	 is given authorship. And the other contributors who have a substantial hand will be consider as co-authors. </p>
             <p>All the others who have minimal participation will be acknowledged in the acknowledgment.	</p>
             <p>Author shall bear the responsibility of listing all the co-authors and others in the paper, and shall have assent of publishing the paper. </p>
             <p>And the corresponding author should provide all the list of co-authors and the others, at the time of submission. Modification of the author’s list in the time of processing is subjected to content of agreement of all the listed authors.</p>
             <p>Aggregate liability of the work is assumed by the author. The responsibility of certifying the query related to the accuracy or integrity of any piece of work is to be suitably researched and settled by every individual author. Authors should guarantee the strategies of the appropriate journals and they may have specific meaning of authorship.</p>
             <h5 class="mt-3">Hazard and Human or Animal Subjects </h5>
             <p>Any subjects of procedure involving dead or living, in toxic elements etc. authors are requested to present them in the paper, and should present all the necessary permissions and shall abide to law and copy rights. </p>
             <p>The outline work is ought to be assured by the authors that the human experiments are pursuant to The Code of Ethics of the World Medical Association (Declaration of Helsinki) while all the animal experiments conform to the ARRIVE guidelines[12] pursuant with the U.K. Animals (Scientific Procedures) Act 1986 and associated guidelines[13], or EU Directive 2010/63/EU on the scientifically anticipated animals safeguard[14], or the U.S. Public Health Service Policy on Humane Care and Use of Laboratory Animals and if applicable, the Animal Welfare Act[15].</p>
             <p>In some cases, AI and ML requests author to submit the respective consents if the manuscript involves individual information, figures, data etc. which require permissions. Author should be able to provide all these whenever he requested to.</p>
             <p>Declaration of Competing Interests.</p>
             <p>WAME specifies conflict of interest as “a variation in an individual’s personal interests (competing interests) with the individual’s scientific responsibilities" and hence there can be an amazement by the publication affairs who is an adequate spectator , on the approval of the individual’s based on their competing interests [17]. Any financial or personal relationships with other individuals or organizations is ought to be revealed by all the authors which can inadequately impact (bias) the work.</p>
             <p>Affirmation of funding or non-funding for the manuscript should be stated. All the sponsors who have helped in any form should be provided with proper acknowledgment.</p>
             <p>Examples of potential conflicts of interest which should be revealed involve recruitment, freelancers, stock control, reimbursement, paid expert testimony, license applications/registrations and allowance or other cash reserves. Potential conflicts of interest should be revealed at the primitively attainable stage.</p>
             <h6 class="mt-3">Notification of Fundamental Errors </h6>            
             <p>Noticing of any error in the time of processing the manuscript or after publication, author must bring the notice to the editor or publisher. In regarding to the errors if publisher or editor gained information from the other sources, author shall provide his cooperation to the editor or publisher.</p>
             <h6 class="mt-3">Image Integrity </h6>
             <p>It is objectionable to amplify, conceal, advance, eliminate, or originate a particular feature within an image. Alteration of brightness, contrast or color balance is agreeable if they don’t conceal or faint any details in the master. Alteration of images for the enhanced transparency is approved, but alteration for the other reasons is considered as systematic moral corruption and will be handled appropriately.</p>
             <p>Authors should abide by with any particular strategy for the graphical images adapted by the appropriate journal, e.g. giving the master image as additional objective with the article, or installing in a magazine.</p>
             <h6>Clinical Trail Transparency </h6>
             <p>JAIML agrees clinical trial transparency and the authors are anticipated to hold the vital standards for the applicable journals in clinical trial registration, for instance the CONSORT guidelines, as further set out in the policies of the relevant journal [1, 2CONSORT standards for randomized trials <a target='_blank' href="http://www.consort-statement.org/"> http://www.consort-statement.org/].</a></p>
             
             <h5 class="mt-3">Ethical Responsibilities of Reviewers </h5>
             <h6 class="mt-3">Contribution to Editorial Decisions </h6>
             <p>AI and ML journal request reviewer to entrust their most cooperation in the editorial decisions with the author. We hope to build a well communication and rapport among reviewers, editorial board and publisher.</p>
             <p>Inform the editor about the inadequacy to review the research reported in a manuscript or its early review is impractical, is known by the selected referee, and reject the member in the review methods.</p>
             <h6 class="mt-3">Confidentiality </h6>
             <p>Reviewers are obliged to disclose the manuscript’s reviews only with the publisher. Disclosing of comments to the author if required is not encouraged without proper consent with the publisher.</p>
             <p>Reviewers are obliged not to use any data/research work/information etc. of the manuscripts they are reviewing for their personal use. Upon written consent from the respective manuscript’s author, they are permitted to use their data under certain conditions.</p>
             <h6 class="mt-3">Alertness to Ethical Issues </h6>
             <p>If the reviewer got attention of any unethical standards in the manuscript, usage of previously published paper’s data, overlap among the manuscripts and any sort related to, we request to bring notice of editor/publisher. An appropriate citation should be adjunct to the earliest report of any declaration for the information, source or contention.</p>
             <h6 class="mt-3">Standards of Objectivity & Competing Interests </h6>
             <p>Reviewing of the manuscript should be led unbiased and should not show any sort of personal prejudice. </p>
             <p>Reviewers are requested to disclose any potentials conflicts with authors, institution, working area (in related to reviewing manuscript). </p>
             <p>For the genuine scientific reasons, the reviewers convey the author to include quotations to the reviewer’s (or their associates’) work and not with the motive of reviewer’s quotation gain or to increase the perceptibility of their performance (or their associates).</p>
             <h5 class="mt-3"> Ethical Responsibilities of Editors </h5>
             <h6 class="mt-3">Publication Decisions </h6>
            <p>Editors are being held as a chief for the journal in deciding the type of research paper to be published in the journal and generally performing in agreement with the appropriate society (for society-owned or guaranteed journals). The approval of the work being referred to and its significance to analysts and per users should consistently guarantee such choices. The editor might be guided by the approaches of the journal's editorial board and compelled by such lawful necessities as will at that point be in power seeing issues, for example, defamation, copyright encroachment and literary theft. The editor may meet with different editors or commentators (or society officials) in settling on these choices.</p>
             <h6 class="mt-3">Peer Review </h6>
             <p>Editors are responsible in evaluating the review process. Assigning the manuscript to reviewer is scrutinized by them. They have to ensure that the manuscript is reviewed at least by the two reviewers or other independent sources. Editors are requested to disclose any potentials conflicts with authors, institution, working area (in related to processing manuscript).</p>
             <h6 class="mt-3">Fair Play </h6>
             <p>Editors are requested to evaluate the manuscript not considering the author’s gender, race, religion, ethnic group, citizenship etc.</p>
             <p>Editor must help the author in understanding the journal policies, ethics and standards of publications. And editor must ensure that the author has transparency and moral establishment towards the editorial policies.</p>
             <h6 class="mt-3">Journal Metrics </h6>
             <p>Any journal metric endeavor to advance the journal’s rate by the editor is neglected. Specifically, the quotations shall not be included for journal’s articles by the editor apart from certifiable scholarly reasons and to the editors interest the author should not add references to the editor’s own article or products and services.</p>
             <h6 class="mt-3">Confidentiality </h6>
             <p>Editor shall abide in maintaining confidentiality of all the data submitted by the author and the communication between reviewers. Editors are allowed in some exceptional circumstances to impart the content with the other editors. And reviewer identity must be maintained confidential.</p>
             <p>Editors are allowed with the written consent from the author to use the unpublished data in the author’s paper. And if any information received from the peer review must not utilize for their personal use. </p>
             <h6 class="mt-3">Declaration of Competing Interests </h6>
             <p>Editor should reveal any of the conflicts to the publisher, and such declaration may be cited in the journal. </p>
             <p>The choice about papers written by the author or by author’s family member or colleagues or that which products or services shall not be associated by the editor. In additional, the entire journal’s customary methods and peer examination is inflicted to any such submissions which must be managed individually of the relevant author/editor and their research team, there must be fine account of the result of the published paper.</p>
             <p>The declaration of potential conflicts of interest related by Elsevier’s policy by authors and reviewers is applied by the editor.</p>
             <h6 class="mt-3">Vigilance over the Published Record </h6>
             <p>The editor should oversee the published manuscripts collaboratively with the publisher for any vigilance, infringement, or mismanagement.</p>
             <p>The monitoring terms in respect to publishing records are author and co-author of the manuscripts, acknowledgment of the complaints, consideration of claims and reorganization of plagiarism.</p>
             <p>Encountering of any misconduct with certain evidence, must bring the notice to the publisher for further corrections, withdrawal, retraction etc.</p>
             <h5 class="mt-3">Ethical Responsibilities of Publisher </h5>
             <h6 class="mt-3">Guardianship of the scholarly record </h6>
             <p>Publisher acknowledging that the role of the journal editor is most privilege to the journal and the efforts made by them are important aspect for journal, substantial work volunteered by the reviewers and the editors-in-chief, in maintaining the integrity of the open access publication.</p>
             <p>JAIML support the editors, reviewers, editors-in-chief and authors, and all the other associates. The work of the integrity, ethics and the moral standard in publishing are the most emphasizing factor for us. We are consequently look forward to secure the rapport among all our folks associated with publishing.</p>
             <p>And we even look to collaborate with the other publisher to establish best standards in practicing the publication ethics.</p>
             <h6 class="mt-3">Safeguard Editorial Independence </h6>
             <p>The editorial decisions are not dependent on any prospective for promoting, reprints or advertising and purely evident on our own.</p>
             <h6 class="mt-3">Collaborate to set industry best practice </h6>
             <p>The editors membership of the Committee on Publication Ethics (COPE) and Crossref Similarity check reports are provided to the editors to promote our editorial systems.</p>
             <h6 class="mt-3">Provide editors with technical, procedural & legal support </h6>
             <p>Associating with the other journals/publisher for the further enhancing of the editor aspects associated with the work is encouraged. Publication aspects in regards to the editors are supported with technical, non-technical and legal prospective.  </p>            
             <h6 class="mt-3">Educate researchers on publishing ethics.</h6>
             <p>Broad education and counsel on distributing moral principles, mainly for fresh career analyzer is also added.</p>
          
        </div>
        
      </div>
  </div>
  <div class="container-fluid" id="backToTopID">
    <button class="btn float-right col-sm-1 scroll-btn" (click)="scrollToElement(target)"><strong><i class="fa fa-angle-up fa-lg"></i></strong></button>
  </div>