<div class="container-fluid">
  <div class="container">
      <div class="row">
          <div class="col-sm-4 mt-2">
              <p class="breadcrumb-text"><strong> Home /</strong> Manuscript Submissions</p>
          </div>
      </div>
  </div>  
</div>
<div class="container">
    <div class="row">
        <div class="col-sm-3 col-left mt-3">
          <app-home></app-home>
    </div>

    <div class="col-sm-9">
        <h2 style="margin-top: 7rem;">
            Submit your manuscript - <a href="mailto:submissions@jaiml.org">submissions@jaiml.org</a>
        </h2>
    </div>
</div>
</div>