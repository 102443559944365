<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-sm-4 mt-2">
                <p class="breadcrumb-text"><strong> Home /</strong> Contact Us</p>
            </div>
        </div>
    </div>  
  </div>
  <div class="container">
      <div class="row">
        <div class="col-sm-3 col-left mt-3">
          <app-home></app-home> 
    </div>

          <div class="col-sm-6">
            <!-- <h2>
              Contact us at - <a href="mailto:contact@jaiml.org">contact@jaiml.org</a>
          </h2> -->

          <div class="">
              <form  [formGroup]="contactForm">
          <div class="col-sm-12">
            <label for="fullName">Full Name</label>  
            <input [class.is-invalid]="formData.fullName.invalid && formData.fullName.touched" type="text"  placeholder="FullName" required formControlName="fullName" class="form-control">
            <small [class.d-none]="formData.fullName.value || formData.fullName.untouched" class="text-danger">Username is required</small>
            <br>
            <label for="subject">Subject</label>  
            <input type="text" [class.is-invalid]="formData.subject.invalid && formData.subject.touched" placeholder="Subject" required formControlName="subject" class="form-control">
            <div *ngIf="formData.subject.invalid && formData.subject.touched">
              <small *ngIf="formData.subject.errors?.required" class="text-danger">Subject is required</small> 
              <small *ngIf="formData.subject.errors?.minlength" class="text-danger">Min. 10 characters required</small> 
            </div>
          </div>
          <br>
          <div class="col-sm-12">
            <label for="email">Email</label>  
            <input type="email" [class.is-invalid]="formData.email.invalid && formData.email.touched"  placeholder="Email" required formControlName="email" class="form-control">
            <div *ngIf="formData.email.invalid && formData.email.touched">
              <small *ngIf="formData.email.errors?.required" class="text-danger">E-mail is required</small> 
              <small *ngIf="formData.email.errors?.email" class="text-danger">E-mail format is incorrect</small> 
            </div>
            <br>
            <label for="phone">Phone</label>  
            <input type="number"  placeholder="Phone Number" formControlName="phone" class="form-control">
           


          </div>
           <div class="col-sm-12"> <br>
            <label for="comments">Comments</label>  
            <textarea type="text" [class.is-invalid]="formData.comments.invalid && formData.comments.touched"  placeholder="Comments" required formControlName="comments" class="form-control"></textarea>
            <div *ngIf="formData.comments.invalid && formData.comments.touched">
              <small *ngIf="formData.comments.errors?.required" class="text-danger">Enter your comments *</small>   
              <small *ngIf="formData.comments.errors?.minlength" class="text-danger">Min 20 chars is required *</small>   
            </div>
            
            <br>
            <button class="btn btn-primary pull-right my-3 col-sm-4" [disabled]="contactForm.invalid" (click)="sendEmail()">Submit</button>
           </div>
        </form>
    </div>
      
          </div>
          <div class="col-sm-3 mt-3">
            <div class="right-side">
                <h4>Our Address</h4>        
                <address class="address-text">
               <strong>  Eunice Publications</strong>  <br>      
               Journal of Artificial Intelligence and Machine Learning(JAIML) <br> 8-2-603, Banjara Hills - 500034.<br>
          <br></address>
          <!-- <i class="fa fa-phone" aria-hidden="true"></i>
          +98(23) xxxxxxxxx.
          <br> -->
          <i class="fa fa-envelope" aria-hidden="true"></i>
          <a href="mailto:contact@jaiml.org">  contact@jaiml.org</a>
              </div>
              <h3 *ngIf="alertMsg" class="alert alert-success mt-4">Thank you for reaching us!!</h3>
          </div>
         
      </div>
      
  </div>