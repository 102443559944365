import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-purpose-of-peer-review',
  templateUrl: './purpose-of-peer-review.component.html',
  styleUrls: ['./purpose-of-peer-review.component.scss']
})
export class PurposeOfPeerReviewComponent implements OnInit {
  journal:any;
  Browse:any;
  constructor() { }

  ngOnInit(): void {
    window.scroll(0,0);
  }

}
