import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manuscript-submission',
  templateUrl: './manuscript-submission.component.html',
  styleUrls: ['./manuscript-submission.component.scss']
})
export class ManuscriptSubmissionComponent implements OnInit {


  journal:any;
  Browse:any;
  constructor() { }

  ngOnInit(): void {
    window.scroll(0,0);
  }

}
