import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-author-guidelines',
  templateUrl: './author-guidelines.component.html',
  styleUrls: ['./author-guidelines.component.scss']
})
export class AuthorGuidelinesComponent implements OnInit {
  journal: any;
  Browse: any;
  constructor() { }

  scrollToTop() {
    window.scroll(0, 0);
  }
  ngOnInit(): void {
    window.scroll(0, 0);
  }
  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
}


