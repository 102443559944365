<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 mt-2">
                <p class="breadcrumb-text"><strong> Home / </strong> Purpose of Peer Review</p>
            </div>
        </div>
    </div>  
  </div>
  
  <div class="container">
      <div class="row">
          <div class="col-sm-3 col-left mt-3">
            
            <app-home></app-home>
  
        </div>

        <div class="col-sm-9">
          <h3 class="mt-3">Journal of Artificial Intelligence and Machine Learning (JAIML)</h3>
          <p>
            Deep gratitude to you for the work to meet the inconceivably high standards of peer-reviewed journals and the proficiency you offered in reviewing.
          </p>
          <p>
            As a crucial feature of scholarly publication and as an important keystone of the scientific process, Peer review serves with two key functions:
          </p>
          <ul>
            <li>Serves as a filter: Assurance of  adequate review of the research prior to publication</li>
            <li>Enhances the attribute of the research: perfection of the key points and correction of the accidental errors can be processed by subtle review of other specialists</li>
          </ul>
          <h4>Conducting the Review</h4>
          <p>The article under the proposal of the review should be done in private and should not be revealed to a third party. The Editor should priory know about raising a notion from colleagues or students concerning the article as in general editors appreciate comments and the review process should be kept as a private by those who are engaged as most journals will not reveal the reviewer’s personality with the author. In support to secure your personality, <i>please do not reveal your name within the text of your review.     </i>  </p>
          <p> <i> You should not attempt to contact the author</i>. Any proposals given while submitting your review should be cautiously made as they will bestow the final decision by the Editor.</p>
          <p>            Depending upon the journal, you will be asked to evaluate the article on a number of criteria. Some journals provide detailed guidance others do not, but normally you would be expected to evaluate the article according to the following:</p>
            <p>You will be queried to assess the article basing upon a list of criteria in accordance with the journal by providing some guidelines or without guidelines but in general you need to assess the article as follows:
            </p>
          </div>
          <div class="row">
            <div class="col-sm-12">
          
            <h4>Originality</h4>
            
              <ul>
                <li>Is the article adequately original and appeal to guarantee publication?</li>
                <li>Does it append to the doctrine of wisdom?</li>
                <li>Does it stick to the requirements of JAIML?</li>
                <li>Is the examined query significant?</li>
                <li>It assists JAIML for the resolution of its novelty and exactness in accordance with its percentile of research value</li>
                <li>Is it placed in the top 25% of papers in this field (Artificial Intelligence Algorithms, Machine Learning and related subjects)?</li>
                <li>Scopus and Google Scholar are the tools that can be used to know the reviews of any area of literature rapidly whenever you wish for</li>
                <li>If the research areas are concealed in prior then the reference can be conveyed for the works to the Editor.</li>
              </ul>
            
            <h4>Structure</h4>
            <p>
              Is the article clearly determined? <br>
Are all the key features (wherever applicable) present: abstract, introduction, methodology, results, conclusions?<br>
Examine each feature on its part: 
            </p>
            <ul>
              <li>Title: Is the article unquestionably explained?</li>
              <li>Abstract: Is the content of the article reflecting?</li>
              <ul>
                <li>The graphical abstracts are involved and verify the contents to enhance by making proposals.</li>
                <li>Introduction:
                  <ul>
                    <li>Are the expectations of the author reached by giving detailed explanation to precision attainment and an evident report of the detected issue? </li>
                    <li>In general, an appropriate introduction to the research should be submitted in context with description of other authors’ conclusion, if any, are being contested or prolonged as there should be an explanation of the research, hypothesis(es) and the research model or method(s) .</li>
                  </ul>
                  </li>
                  <li>
                    Method: 
                    <ul>
                      <li>Is there an exact interpretation of the data collection by the author?</li>
                      <li>Is the raised query being responded by a fitting model?</li>
                      <li>Do you have enough details for duplicating the research? </li>
                      <li>Are the methods in track of the article? </li>
                      <li>Are they arranged significantly?</li>
                      <li>Is there thorough elucidation in case of new methods?</li>
                      <li>Is there suitable sampling? </li>
                      <li>Is the apparatus and supplies been appropriately explained?</li>
                      <li> Is there clear record of the type of data by the article and is the author accurate in explaining the proportions?</li>
                    </ul>
                  </li>
                  <li>
                      Results:
                      <ul>
                        <li>The author(s) should describe here about how he/she/they found out clearly in a logical sequence in their research. If a suitable investigation is performed it should be considered.</li>
                        <li>Are the statistics right?</li>
                        <li>Kindly inform the Editor in case of any inconvenience with the statistics while submitting your report and the interpretation of results should be excluded in this section.</li>
                      </ul>

                  </li>
                  <li>
                    Conclusion/Discussion:
                    <ul>
                      <li>Are the assert (s) in this section seem reasonable and endorsed by the results?</li>
                      <li>Did the authors state how the results are in reference to the expectancy and to the prior research? </li>
                      <li>Are the prior theories of the article are assisted or opposed? </li>
                      <li>Is there an elucidation of how the research has moved the body of scientific knowledge ahead?</li>
                    </ul>
                  </li>
                  <li>
                    Language: You do not have to correct the English if your article is appearing poor and tricky to figure out the science with grammatical mistakes, however, this should be conveyed to the Editor.
                  </li>
                  <li>
                    Eventually, will the figures and tables inform the reader about the entire article?
                    <ul>
                      <li>Do they play a vital role?</li>
                      <li>Do they elucidate precise data?</li>
                      <li>Are they uniform, e.g. bars in charts are the same width, the scales on the axis are logical.</li>
                    </ul>
                  </li>
              </ul>
            </ul>
            <p> <strong><i>Previous Research</i></strong> <br> <br>
              Will the referral of the article perform adequately if it was created in accordance with the prior research? Is there any crucial performance rejected? Is the referral precise?
              </p>
              <p> <strong> <i>Ethical Issues</i></strong></p>
                <ul>
                  <li>Plagiarism: Kindly let the Editor know instantly about the suspicion of an article to be a significant replica of another by providing a specific explanation of the prior work.</li>
                  <li>Fraud: Tracking a fraudster is complicated but let the Editor know if it seems that the report in an article is fallacious.</li>
                  <li>Other ethical concerns: Is the privacy retained for the medical research? Are the agreed standards of the moral treatment of animal or human subjects infringed? If so, the Editor should recognize them.</li>
                  
                </ul>
                
                <h4>Communicating Your Report to the Editor</h4>
                <p>Upon the completion of assessment of the article, record your report and if you resemble to be missing your deadline then inform the Editor mannerly. <br>                
There might be an appeal for completion of form or for the overview of your remarks by some journals to comprehend the features of a paper and anyhow the appeal of a brief summary of the article will be supportive from the commencement which acts a twin role to the Author(s) and Editor by disclosing the report specifics and reassuring that they have figured out the article. <br>
All the key features along with the highlighted remarks which were mentioned earlier should be included in the report in which the comments should be considerate and valuable without any individual remarks or personal information including your name. <br>
An explanation about the presence of any imperfections should be enlightened to both Editor and Author(s) in perception to the comments are in accordance to your own opinion or a reflection of data <br>
It is preferable to retain the Editor’s most prioritizing classification of the article while proposing an article.</p>

<ul>
  <li>Reject (explain reason in report)</li>
  <li> Accept (without revision)</li>
  <li>Revise (either major or minor)</li>
  <li>Last, clearly identify what revision is required, and indicate to the Editor whether or not you would be happy to review the revised article. </li>
</ul>
        </div>

      </div>
      </div>
  </div>
  