<h4 class="menu-item mb-2 " routerLink='/jaiml' routerLinkActive="menu-active">Home</h4>
<h4 class="menu-item mb-0" (click)="Browse =!Browse" routerLinkActive="menu-active" >Browse
  <i *ngIf="!Browse" class="fa fa-angle-down text-light float-right menu-animation" aria-hidden="true"></i>
  <i *ngIf="Browse" class="fa fa-angle-up text-light float-right " aria-hidden="true"></i>
</h4>
<div *ngIf="Browse">
  <h6 class="sub-menu-item mb-0 " [routerLink]="['/current-issue']" routerLinkActive="menu-active" > &nbsp;&nbsp;&nbsp;Current Issue</h6>
<h6 class="sub-menu-item mb-0" [routerLink]="['/article-in-press']" routerLinkActive="menu-active">&nbsp;&nbsp;&nbsp;Article in Press</h6>
<h6 class="sub-menu-item mb-2" [routerLink]="['/keyword-index']" routerLinkActive="menu-active">&nbsp;&nbsp;&nbsp;Keyword Index</h6>

</div>
<h4 class="menu-item mt-2 mb-0" (click)= "journal =! journal">
    Journal Info
    <i *ngIf="!journal" class="fa fa-angle-down text-light float-right menu-animation" aria-hidden="true"></i>
    <i *ngIf="journal" class="fa fa-angle-up text-light float-right " aria-hidden="true"></i>
</h4>
<div *ngIf="journal"> 
<h6 class="sub-menu-item mb-0" [routerLink]="['/aims-scope']" routerLinkActive="menu-active" >&nbsp;&nbsp;&nbsp;Aims and Scope</h6>
<h6 class="sub-menu-item mb-0" [routerLink]="['/editorial-process']" routerLinkActive="menu-active">&nbsp;&nbsp;&nbsp;Editorial Process</h6>
<h6 class="sub-menu-item mb-0"[routerLink]="['/ethical-responsibilites']" routerLinkActive="menu-active">&nbsp;&nbsp;&nbsp;Ethical Responsibilities</h6>
<h6 class="sub-menu-item mb-0"[routerLink]="['/editorial-board']" routerLinkActive="menu-active">&nbsp;&nbsp;&nbsp;Editorial Board</h6>
</div>

<h4 class="menu-item my-2" [routerLink]="['/author-guidelines']" routerLinkActive="menu-active">Author Guidelines</h4>
<h4 class="menu-item mb-2" [routerLink]="['/manuscript-submission']" routerLinkActive="menu-active">Manuscript Submissions</h4>
<h4 class="menu-item mb-2"  [routerLink]="['/contact']" routerLinkActive="menu-active">Contact</h4>

<!-- <button type="button" class="btn btn-primary col-sm-12 linkedin"><h4 class="mb-2">LinkedIn</h4></button> -->
<a href="https://www.linkedin.com/in/editor-jaiml-680a56204/" target="-blank" class="mb-5 btn btn-block btn-social btn-linkedin linkedin">
<span class="fa fa-linkedin"></span> LinkedIn
</a>