import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-keyword-index',
  templateUrl: './keyword-index.component.html',
  styleUrls: ['./keyword-index.component.scss']
})
export class KeywordIndexComponent implements OnInit {
  journal:any;
  Browse:any;
  constructor() { }

  ngOnInit(): void {
  }

}
