import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare let Email: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  journal: any;
  Browse: any;
  contactForm: FormGroup;
  alertMsg = false;
  constructor(private formBuild: FormBuilder) {

    this.contactForm = this.formBuild.group(
      {
        fullName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        subject: ['', [Validators.required, Validators.minLength(10)]],
        phone: ['',],
        comments: ['', [Validators.required, Validators.minLength(20)]]
      }
    )


  }

  ngOnInit(): void {
    window.scroll(0, 0);

  }

  get formData() {
    return this.contactForm.controls;
  }


  resetForm() {
    this.contactForm.reset();
  }

  sendEmail() {

    // Email.send({
    //   Host: "smtp.elasticemail.com",
    //   Username: "contact@jaiml.org",
    //   // Username: "spatnala2@gmail.com",
    //   Password: "7472ECA0FBE4E4C17089663C3E0EE5F2733E",
    //   // Password: "66EAB6DE47C7DE812E4E3BD23CD0BFFC14F8",
    //   To: this.contactForm.value.email,
    //   From: "contact@jaiml.org",
    //   Subject: "Thanks for reaching us",
    //   Body: `<h3>Hello ${this.contactForm.value.fullName},</h3> <br>
    //   <h4> We are working your issue and get back to you very soon. </h4>
    //   <h5>Regards, <br> Journal of Artificial Intelligence and Machine Learning.</h5>
    //   `


    // })
    // .then((message) => {

    Email.send({
      Host: "smtp.elasticemail.com",
      Username: "contact@jaiml.org",
      Password: "7472ECA0FBE4E4C17089663C3E0EE5F2733E",
      To: "contact@jaiml.org",
      From: "contact@jaiml.org",
      Subject: "You have been contacted",
      Body: `<strong><h1>Name: ${this.contactForm.value.fullName},</h1></strong>
        <br> <strong><h3>Email: ${this.contactForm.value.email}, </h3></strong>
        <br> <strong> <h3>SubjectLine: ${this.contactForm.value.subject},</h3></strong>
        <br> <strong><h3>Comments: ${this.contactForm.value.comments},<h3></strong>
        <br>  <strong><h3> Phone: ${this.contactForm.value.phone}.</h3></strong>
        <br>
       <i> © Copyrights reserved to 
JAIML is licensed under a Creative Commons Attribution 4.0 International License.</i>
        `
    }).then((res: any) => {
      this.resetForm();
      this.alertMsg = true;
      setTimeout(
        () => {
          this.alertMsg = false;
        }
        , 2000)
    }).then(() => {

    })
  }



}

  //testing for 


