<div class="container-fluid">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 mt-2">
        <p class="breadcrumb-text"><strong> Home / </strong> Call for Papers</p>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-sm-3 col-left mt-3">
      <app-home></app-home>
    </div>
    <div class="col-sm-9 mt-3 p-3 mb-3 callPapers-box">
      <p>
        Journal of Artificial Intelligence and Machine Learning (JAIML) is an
        international and interdisciplinary peer-reviewed open access journal
        dedicated to the rapid dissemination of cutting-edge research results to
        the global artificial intelligence (AI) community.
      </p>
      <p>
        It publishes original research articles, reviews, communications, that
        offer substantial new insight into any field of study that involves
        Artificial Intelligence (AI). Topic of the interest are as follows, but
        not restricted to:
      </p>
      <ul>
        <div class="row">
          <div class="col-sm-6">
            <li>Automated Reasoning and Inference</li>
            <li>Case Based Reasoning</li>
            <li>Cognition and AI</li>
            <li>Commonsense Reasoning</li>
            <li>Computational Intelligence</li>
            <li>Computer Vision & Speech Understanding</li>
            <li>Data Mining & Machine Learning Tools</li>
            <li>Pattern Recognition</li>
            <li>Ethical AI</li>
          </div>
          <div class="col-sm-6">
            <li>Fuzzy Logic</li>
            <li>Heuristic Search</li>
            <li>Human-Computer Interaction</li>
            <li>Intelligent Systems and Robotics</li>
            <li>Knowledge Representation</li>
            <li>Multi-agent Systems</li>
            <li>Natural Language Processing (NLP)</li>
            <li>Neural Networks</li>
          </div>
        </div>

        <li>
          AI & ML Applications in the areas of Agriculture, Biology, Business
          and Social sciences, Education, Engineering, Medicine and Healthcare.
        </li>
      </ul>
      <p>
        Our aim is to encourage scientists and engineers to publish their
        experimental and theoretical research in as much detail as possible.
      </p>
      <p>
        The
        <a href="https://jaiml.org/#/editorial-board"> Editorial Board </a>of
        Journal of Artificial Intelligence and Machine Learning invites AI
        scholars, researchers, scientists and engineers around the globe to hand
        in articles on their research dealing with for the First regular issue
        (Volume 1, Issue 1, June 2023).
      </p>
      <h6 class="text-danger">
        Submission Deadline: 31<sup>st</sup> March 2024
      </h6>
      <p>
        Submit your paper as an email attachment to the Editorial Office. We
        look forward for your submission and inquiries of any kind. For
        submissions and queries, please contact Editorial Office at
        <a href="mailto:submissions@jaiml.org"> submissions@jaiml.org</a>
      </p>
    </div>
  </div>
</div>


<script type="application/ld+json" class="yoast-schema-graph">
  {
  "@context":"https://schema.org",
  "@graph":[
  {
  "@type":"CollectionPage",
  "@id":"https://uat.miraclesoft.com/company/awards-milestones",
  "url":"https://uat.miraclesoft.com/company/awards-milestones",
  "name":"Awards and Recognition Miracle Software Systems",
  "isPartOf":{"@id":"https://uat.miraclesoft.com/company/awards-milestones"},
  "primaryImageOfPage":
  {
  "@id":"https://uat.miraclesoft.com/company/awards-milestones"
  },
  "Image":{
  "@id":"https://images.miraclesoft.com/company/awards-milestones/awards-header.svg"
  },
  "thumbnailUrl":"https://images.miraclesoft.com/company/awards-milestones/awards-header.svg",
  "inLanguage":"en-US"
  },
  {
  "@type":"ImageObject",
  "inLanguage":"en-US",
  "@id":"https://images.miraclesoft.com/company/awards-milestones/awards-header.svg",
  "url":"https://images.miraclesoft.com/company/awards-milestones/awards-header.svg",
  "contentUrl":"https://images.miraclesoft.com/company/awards-milestones/awards-header.svg",
  "Width":"1200","height":"675"
  },
  {
  "@type":"WebSite",
  "@id":"https://uat.miraclesoft.com/company/awards-milestones",
  "url":"https://uat.miraclesoft.com/company/awards-milestones",
  "name":"Miracle Software Systems",
  "description":"We are proud to share the awards and accolades we have received and the milestones we have achieved throughout our 26-year history.",
  "Publisher":
  {
  "@id":"https://uat.miraclesoft.com/company/awards-milestones"
  },
  "potentialAction":
  [
  {
  "@type":"SearchAction",
  "Target":
  {
  "@type":"EntryPoint",
  "urlTemplate":"https://uat.miraclesoft.com/company/awards-milestones"
  },
  "query-input":"Awards and Milestones"
  }
  ]
  "inLanguage":"en-US",
    ] }
  </script>




  <script type="application/ld+json" class="yoast-schema-graph">
    {
    "@context":"https://schema.org",
    "@graph":[
    {
    "@type":"CollectionPage",
    "@id":"https://uat.miraclesoft.com/company/awards-milestones",
    "url":"https://uat.miraclesoft.com/company/awards-milestones",
    "name":"Awards and Recognition Archives Miracle Software Systems",
    "isPartOf":{"@id":"https://uat.miraclesoft.com/company/awards-milestones"},
    "primaryImageOfPage":
    {
    "@id":"https://uat.miraclesoft.com/company/awards-milestones"
    },
    "Image":{
    "@id":"https://images.miraclesoft.com/company/awards-milestones/awards-header.svg"
    },
    "thumbnailUrl":"https://images.miraclesoft.com/company/awards-milestones/awards-header.svg",
    "inLanguage":"en-US"
    },
    {
    "@type":"ImageObject",
    "inLanguage":"en-US",
    "@id":"https://images.miraclesoft.com/company/awards-milestones/awards-header.svg",
    "url":"https://images.miraclesoft.com/company/awards-milestones/awards-header.svg",
    "contentUrl":"https://images.miraclesoft.com/company/awards-milestones/awards-header.svg",
    "Width":"1200","height":"675"
    },
    {
    "@type":"WebSite",
    "@id":"https://uat.miraclesoft.com/company/awards-milestones",
    "url":"https://uat.miraclesoft.com/company/awards-milestones",
    "name":"Miracle Software Systems",
    "description":"We are proud to share the awards and accolades we have received and the milestones we have achieved throughout our 26-year history.",
    "Publisher":
    {
    "@id":"https://uat.miraclesoft.com/company/awards-milestones"
    },
    "potentialAction":
    [
    {
    "@type":"SearchAction",
    "Target":
    {
    "@type":"EntryPoint",
    
    "urlTemplate":"https://uat.miraclesoft.com/company/{search_term_string}"
    },
    "query-input":"required name=awards-milestones"
    }
    ],
    "inLanguage":"en-US"
    },
    {
    "@type":"Organization",
    "@id":"https://uat.miraclesoft.com/company/awards-milestones",
    "name":"Salesforce",
    "url":"https://uat.miraclesoft.com/company/awards-milestones",
    "Logo":
    {
    "@type":"ImageObject",
    "inLanguage":"en-US",
    "@id":"https://images.miraclesoft.com/company/awards-milestones/awards-header.svg",
    "url":"https://images.miraclesoft.com/company/awards-milestones/awards-header.svg",
    "contentUrl":"https://images.miraclesoft.com/company/awards-milestones/awards-header.svg",
    "Width":"1200",
    "Height":"630",
    "caption":"Salesforce"},
    "Image":
    {
    "@id":"https://images.miraclesoft.com/company/awards-milestones/awards-header.svg"
    },
    "sameAs":["https://www.linkedin.com/company/miraclesoft/",
    "https://www.facebook.com/miracle45625/",
    "https://twitter.com/miraclesoft"
    ]
    }
    ]
    }
    </script>
    
  










